// TODO - PÁGINA INICIAL

import { EMDESENVOLVIMENTO } from "../ImportBackend/Config/emDesenvolvimento"

// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/ContaCorrente'
export const LOGARBACKENDAPI: boolean = EMDESENVOLVIMENTO && true
export const LOGARBACKENDAPIRESULTADO: boolean = EMDESENVOLVIMENTO && false
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Pacientes'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/LoginPaciente'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Dentistas'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Clinicas'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Usuarios'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/FeriadosNacionais'

// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Saldos'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/DownloadEcuro'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/UnidadesOrganizacionais'
export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Clientes'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/SetupWhatsAPP'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/TesteBox'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/relAgendamento'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/'

export const LINK_INSTAGRAM_PAGINA_OBRIGADO: string = 'https://www.instagram.com/vamossorrir.mg'

export const LINK_FACEBOOK_PAGINA_OBRIGADO: string = 'https://www.facebook.com/vamossorrir.mg'

